<template>
    <div>
        <b-row v-if="!datatable.isLoading">
            <b-col md="12">
                <b-table bordered striped small show-empty :fields="questionFields" :items="questions"
                    :empty-text="this.$t('there_are_no_records')"></b-table>
            </b-col>
            <b-col md="12" class="mt-4">
                <multi-selectbox :options="commentQuestionOptions" :multiple="false" v-model="commentQuestion"
                    :placeholder="$t('survey_comment_question_select')" />
            </b-col>
            <b-col md="12" class="mt-4">
                <datatable :title="commentQuestion ? this.$t('comment_answers') : this.$t('comment_questions')" :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="commentRows"
                   :showPaginate="false" :total="datatable.total" :queryParams="datatable.queryParams" :lineNumbers="false"
                    @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"
                    v-show="datatable.showTable" />
            </b-col>
        </b-row>
  

        <template v-else>
            <loading v-show="true" :descriptionShow="true" :description="$t('survey_results_loading')" />
        </template>
    </div>
</template>
<script>

import SurveyService from "@/services/SurveyService";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox";
import Datatable from "@/components/datatable/Datatable";


export default {
    name: "InstructorResultDetail",
    props: {
        detailData: {
            type: Object
        }
    },
    components: {
        MultiSelectbox,
        Datatable
    },
    data() {
        return {
            commentQuestionOptions: [],
            surveyComments: [],
            selectComment: null,
            questionFields: [
                {
                    key: this.getLocaleField("question"),
                    label: this.$t('survey_question_about_course'),
                    sortable: false
                },
                {
                    key: 'instructor_avg',
                    label: this.$t('survey_staff_avg'),
                    sortable: false,
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    key: 'faculty_avg',
                    label: this.$t('survey_faculty_avg'),
                    sortable: false,
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    key: 'university_avg',
                    label: this.$t('survey_university_avg'),
                    sortable: false,
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
            ],
            questions: [],
            commentQuestion: null,

            datatable: {
                isLoading: false,
                columns: [
                    {
                        field: this.getLocaleField("question"),
                        sortable: false,
                    }
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: true,
                queryParams: {
                    filter: {
                        survey_id: this.$route.params.surveyId
                    },
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },
        }
    },
    computed: {
        commentRows() {
            if (this.commentQuestion) {
                return this.selectComment.answers.map(i => ({ question: i, question_en: i }))
            }
            return this.datatable.rows
        }
    },
    watch: {
        detailData(value) {
            this.getResults(value)
        },
        commentQuestion: {
            handler(val) {
                if (!val) {
                    this.datatable.rows = this.surveyComments
                    return
                }

                const findComment = this.surveyComments.find(i => i.survey_question_id === val)
                if (findComment) {
                    this.selectComment = { ...findComment }
                }

                this.datatable.queryParams.page = 1
                this.datatable.queryParams.limit = 20
            },
            immediate: true
        }
    },
    mounted() {
        this.datatable.rows = []
        this.getResults(this.detailData)
    },
    methods: {
        getResults(data) {
            this.datatable.isLoading = true;
            SurveyService.instructorResults(data.filter.semester_id, data.instructor_id, data.section_id).then(response => {
                this.questions = response.data.data.avg
                this.datatable.rows = response.data.data.comment
                this.surveyComments = response.data.data.comment
                this.commentQuestionOptions = this.datatable.rows.map(i => ({ value: i.survey_question_id, text: this.getLocaleText(i, "question") }))
            }).catch(e => {
                this.$toast.error(this.$t('api.' + e.data.message));
            }).finally(() => {
                this.datatable.isLoading = false;
            });
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
        },
    }
}
</script>
