<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('survey_instructor_results')" @filter-div-status="datatable.filterStatus = $event">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('survey_instructor_results')" @filter-div-status="datatable.filterStatus = $event">
                </HeaderMobile>
            </template>
            <datatable-filter
                v-show="datatable.filterStatus"
                @filter="filter"
                @filterClear="filterClear"
                :exportExcel="true"
                @exportExcel="excelExport">
                <ValidationObserver ref="form">
                    <b-row>
                        <b-col sm="6" md="4">
                            <b-form-group :label="$t('semesters')">
                                <ValidationProvider name="semester_id" rules="required" v-slot="{ errors }">
                                    <semesters-selectbox v-model="datatable.queryParams.filter.semester_id"
                                        :setActive="true" :validate-error="errors[0]">
                                    </semesters-selectbox>
                                </ValidationProvider>
                            </b-form-group>
                        </b-col>
                        <b-col sm="6" md="4">
                            <b-form-group :label="$t('course')">
                                <ValidationProvider name="course_code" rules="" v-slot="{ errors }">
                                    <course-auto-complete v-model="datatable.queryParams.filter.course_code"
                                        :validate-error="errors[0]" resultType="code" />
                                </ValidationProvider>
                            </b-form-group>
                        </b-col>
                        <b-col sm="6" md="4">
                            <b-form-group :label="$t('teaching_staff')">
                                <ValidationProvider name="instructor_id" rules="" v-slot="{ errors }">
                                    <staff-auto-complete v-model="datatable.queryParams.filter.instructor_id"
                                        :validate-error="errors[0]" />
                                </ValidationProvider>
                            </b-form-group>
                        </b-col>
                        <b-col sm="6" md="4">
                            <b-form-group :label="$t('faculty')">
                                <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code" :multiple="false">
                                </faculty-selectbox>
                            </b-form-group>
                        </b-col>
                        <b-col sm="6" md="4">
                            <b-form-group :label="$t('department')">
                                <department-selectbox
                                    v-model="datatable.queryParams.filter.department_code"
                                    :multiple="false"
                                    :is_faculty_code_required="true"
                                    :faculty_code="datatable.queryParams.filter.faculty_code">
                                </department-selectbox>
                            </b-form-group>
                        </b-col>
                        <b-col sm="6" md="4">
                            <b-form-group :label="$t('program')">
                                <program-selectbox
                                    v-model="datatable.queryParams.filter.program_code"
                                    :is_department_code_required="true"
                                    :department_code="datatable.queryParams.filter.department_code"
                                    :multiple="false">
                                </program-selectbox>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </ValidationObserver>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                :total="datatable.total" :queryParams="datatable.queryParams" :lineNumbers="false"
                @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable">
            </datatable>
            <CommonModal ref="detailModal" size="xl" @bHideModalHeaderClose="detailModalStatus = false">
                <template v-slot:CommonModalTitle>
                    {{ $t("detail").toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <InstructorResultDetail :detailData="detailData" v-if="detailModalStatus" />
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import qs from "qs";
import SurveyService from "@/services/SurveyService";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox";
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
import StaffAutoComplete from "@/components/interactive-fields/StaffAutoComplete";
import CourseAutoComplete from "@/components/interactive-fields/CourseAutoComplete";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import CommonModal from "@/components/elements/CommonModal";
import InstructorResultDetail from "./InstructorResultDetail";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox"
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox"
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox"

export default {
    name: "InstructorResults",
    components: {
        MultiSelectbox,
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        ValidationObserver,
        ValidationProvider,
        SemestersSelectbox,
        StaffAutoComplete,
        CourseAutoComplete,
        CommonModal,
        InstructorResultDetail,
        FacultySelectbox,
        DepartmentSelectbox,
        ProgramSelectbox
    },
    metaInfo() {
        return {
            title: this.$t("survey_instructor_results"),
        };
    },
    data() {
        return {
            surveyResultFilter: {},
            detailModalStatus: false,
            id: 0,
            form: {
                user_type: [],
                student_numbers: null,
            },
            formId: null,
            formProgress: null,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t("detail"),
                                class: "ri-list-check",
                                callback: (row) => {
                                    this.showDetail(row.section_id,row.instructor_id)
                                },
                            }
                        ],
                    },
                    {
                        label: this.$t("academic_year"),
                        field: "academic_year",
                        sortable: false,
                    },
                    {
                        label: this.$t("semester"),
                        field: this.getLocaleField("semester_name"),
                        sortable: false,
                    },
                    {
                        label: this.$t("course_code"),
                        field: "course_code",
                        sortable: false,
                    },
                    {
                        label: this.$t("course_name"),
                        field: this.getLocaleField("course_name"),
                        sortable: false,
                    },
                    {
                        label: this.$t("department"),
                        field: this.getLocaleField("course_department_name"),
                        sortable: false,
                    },
                    {
                        label: this.$t("faculty"),
                        field: this.getLocaleField("course_faculty_name"),
                        sortable: false,
                    },
                    {
                        label: this.$t("program_code"),
                        field: "course_program_code",
                        sortable: false,
                    },
                    {
                        label: this.$t("program_name"),
                        field: this.getLocaleField("course_program_name"),
                        sortable: false,
                    },
                    {
                        label: this.$t("instructor"),
                        field:"instructor",
                        sortable: false,
                    },
                    {
                        label: this.$t("section"),
                        field: "section",
                        sortable: false,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {
                        survey_id: this.$route.params.surveyId
                    },
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },

            facultyOptions: [],
            departmentOptions: [],
            programOptions: [],
            detailData: {}
        };
    },

    methods: {

        formClear() {
            this.formId = null
            this.formProgress = null
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        async getRows() {
            const isValid = await this.$refs.form.validate();
            if (isValid) {
                this.datatable.showTable = true;

                const config = {
                    params: {
                        ...this.datatable.queryParams,
                    },
                    paramsSerializer: (params) =>
                        qs.stringify(params, { encode: false }),
                }

                SurveyService.instructorList(config).then(response => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total
                })
            }
        },

        showDetail(sectionId,instructor_id) {
            this.detailData = {
                section_id: sectionId,
                instructor_id,
                filter: this.datatable.queryParams.filter
            }
            this.detailModalStatus = true
            this.$refs.detailModal.$refs.commonModal.show();
        },

        excelExport(){
            const config = {
                params: {
                    ...this.datatable.queryParams,
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false }),
            }
            SurveyService.instructorSurveyReport(config).then(response => {
                this.$toast.success(this.$t('api.'+response.data.message))
                this.$router.push('/job/logs/detail/'+response.data.data.log_id)
            }).catch(e => {
                this.showErrors(e)
            })
        }

    }
}
</script>
